export const TWO_UP_GRID_PROPS = {
  columns: 1,
  gap: 32,
  tablet: {
    columns: 2,
    gap: 24,
  },
  desktop: {
    gap: 40,
  },
};

export const THREE_UP_GRID_PROPS = {
  columns: 1,
  gap: 32,
  tablet: {
    columns: 3,
    gap: 16,
  },
  desktop: {
    gap: 32,
  },
};

export const FOUR_UP_GRID_PROPS = {
  columns: 2,
  gap: 32,
  tablet: {
    columns: 3,
    gap: 16,
  },
  desktop: {
    columns: 4,
    gap: 32,
  },
};
