import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Carousel } from '@nintendo-of-america/component-library';

import * as S from './SectionCarousel.styles';
import { RichText } from '@local/components';

const SectionCarousel = ({ items, variant, scrim, ...props }) => {
  const [unifiedSlideIndex, setUnifiedSlideIndex] = useState(0);
  const carouselInnerRef = useRef();
  const doBeforeSlide = (originSlide, destinationSlide) => {
    setUnifiedSlideIndex(destinationSlide);

    if (originSlide !== destinationSlide) {
      carouselInnerRef.current
        ?.querySelectorAll('video')
        .forEach((v) => v.pause());
    }
  };

  const hasItems = items && items.length > 0;
  if (hasItems) {
    return (
      <S.BottomPadding $scrim={scrim}>
        <Carousel
          beforeSlide={doBeforeSlide}
          slideIndex={unifiedSlideIndex}
          innerRef={carouselInnerRef}
          slideBorderColor="transparent"
          {...props}
        >
          {items.map((slide, index) => {
            const mediaDisplay =
              slide.primary.resourceType === 'video' ? (
                <S.StyledVideoPlayer
                  key={`${slide.primary.assetPath}-${index}`}
                  assetPath={slide.primary.assetPath}
                />
              ) : (
                <S.StyledImage
                  key={`${slide.primary.assetPath}-${index}`}
                  assetPath={slide.primary.assetPath}
                  alt={slide.alt}
                  variant={variant}
                  showPlaceholder={index !== 0}
                  lazyLoad={index !== 0}
                />
              );

            if (slide.caption) {
              return (
                <S.SlideWithCaption
                  key={`${slide.primary.assetPath}-${index}-captioned`}
                  $variant={variant}
                >
                  {mediaDisplay}
                  <S.TextWrapper variant={variant} $scrim={scrim}>
                    <RichText data={slide.caption} />
                  </S.TextWrapper>
                </S.SlideWithCaption>
              );
            }
            return mediaDisplay;
          })}
        </Carousel>
      </S.BottomPadding>
    );
  }
  return null;
};

SectionCarousel.defaultProps = {
  variant: 'stack',
};

SectionCarousel.propTypes = {
  items: PropTypes.array,
  variant: PropTypes.oneOf(['split', 'stack', 'no-caption']),
  scrim: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.string,
  }),
};

export default SectionCarousel;
