import { css } from 'styled-components';

export const borderStyles = css`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, $borderColor }) =>
    theme.color[$borderColor]
      ? theme.color[$borderColor]
      : $borderColor || theme.borderColor || theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius};
`;
