import styled, { css } from 'styled-components';

import { Image, VideoPlayer } from '@nintendo-of-america/component-library';

export const BottomPadding = styled.div`
  padding-bottom: 2rem;

  div:has(> p) {
    padding-top: ${({ $scrim }) => ($scrim?.backgroundColor ? '1rem' : '0')};
  }
`;

export const StyledVideoPlayer = styled(VideoPlayer)`
  overflow: hidden; /* Respect border-radius */
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const SlideWithCaption = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    'image'
    'caption';
  align-items: center;
  text-align: center;
  grid-gap: ${({ theme }) => theme.spacing[24]};
  ${({ $variant }) =>
    $variant === 'split' &&
    css`
      text-align: left;
      grid-template-areas:
        'image'
        'caption';
    `}
  ${({ theme }) => theme.mediaQuery.tablet} {
    ${({ $variant }) =>
      $variant === 'split' &&
      css`
        grid-template-columns: 4fr 6fr;
        grid-template-areas: 'caption image';
      `}
  }
`;

export const TextWrapper = styled.div`
  grid-area: caption;
  align-self: center;
  padding: ${({ theme }) => theme.spacing[32]};
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  border-radius: ${({ $scrim }) => $scrim?.borderRadius};
  background-color: ${({ $scrim }) => $scrim?.backgroundColor};
`;

export const StyledImage = styled(Image)`
  grid-area: image;
  display: block;
  align-self: center;
  margin: 0 auto;

  img {
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    width: auto;
  }

  ${({ variant }) =>
    variant === 'split' &&
    css`
      img {
        max-height: 250px;
      }
    `}

  ${({ theme }) => theme.mediaQuery.tablet} {
    ${({ variant }) =>
      variant === 'split' &&
      css`
        img {
          max-height: 600px;
        }
      `}
  }
`;
