import PropTypes from 'prop-types';
import { isValidElement } from 'react';
import { Heading } from '@nintendo-of-america/component-library';

import { RichText } from '@local/components';

import * as S from './SectionItem.styles.js';

const SectionItem = ({
  children,
  className,
  dividerElement,
  heading,
  content,
  options,
  linkStyle,
  productProps,
}) => {
  const title = isValidElement(heading) ? (
    heading
  ) : (
    <Heading variant="h1">{heading}</Heading>
  );

  return (
    <S.Wrapper
      $bg={options?.bg}
      $scrim={options?.scrim}
      $borderRadius={options?.borderRadius}
      $textSize={options?.textSize}
      $textAlign={options?.textAlign}
      className={className}
    >
      {heading ? title : null}
      {dividerElement}
      {content ? (
        <RichText
          linkStyle={linkStyle}
          data={content}
          productProps={productProps}
        />
      ) : null}
      {heading ? <Heading.NewLevel>{children}</Heading.NewLevel> : children}
    </S.Wrapper>
  );
};

SectionItem.defaultProps = {
  options: {},
  dividerElement: null,
};

SectionItem.propTypes = {
  // Display Short Text field as `<h2>`
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // Display the Rich Text field
  content: RichText.propTypes.data,
  options: PropTypes.shape({
    // Apply a light or dark scrim
    scrim: PropTypes.oneOf(['light', 'dark']),
    // Apply a background color
    bg: PropTypes.string,
    // Set the `text-align` CSS property directly
    textAlign: PropTypes.string,
    // Set the text size following the variants of <Text> in Design System
    textSize: PropTypes.oneOf(['legal']),
    // Value for the box-shadow
    boxShadow: PropTypes.string,
    borderRadius: PropTypes.string,
  }),
  dividerElement: PropTypes.node,
  linkStyle: PropTypes.oneOf(['light', 'dark']),
};

export default SectionItem;
