import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  text-align: ${({ $textAlign }) => $textAlign || 'inherit'};
  ${({ theme, $bg, $scrim, $borderRadius }) =>
    ($bg || $scrim) &&
    css`
      padding: ${theme.spacing[24]} ${theme.spacing[32]};
      background-color: ${$bg ? $bg : $scrim && theme.scrim[$scrim]};
      border-radius: ${$borderRadius || theme.borderRadius};

      & p a span {
        /* Darken primary link color on light scrim to comply with WCAG AA Color Contrast */
        filter: ${$scrim === 'light' ? 'brightness(0.8)' : 'brightness(1)'};
      }
    `};
  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: ${boxShadow};
    `}
  ${({ theme, $textSize }) =>
    $textSize === 'legal' &&
    css`
      &,
      > p {
        /* Contentful Rich Text Editor only renders <p> tags. I apologize. */
        font-weight: ${theme.font.weight.light};
        font-size: ${theme.font.size.legalMobile};
        ${theme.mediaQuery.tabletSmall} {
          font-size: ${theme.font.size.legalDesktop};
        }
      }
    `}
`;
