import styled, { css } from 'styled-components';

import { Image, VideoPlayer } from '@nintendo-of-america/component-library';

import { borderStyles } from '../border.styles';
import { Video } from '@nintendo-of-america/component-library';

export const VideoPlayerContainer = styled.div`
  ${({ $videoPlayerContainerStyles }) =>
    $videoPlayerContainerStyles &&
    css`
      ${$videoPlayerContainerStyles}
    `}
`;

export const StyledVideoPlayer = styled(VideoPlayer)`
  overflow: hidden; /* Respect border-radius */
  ${({ $border }) => ($border === 'none' ? null : borderStyles)};
  z-index: ${({ $zIndex }) => $zIndex ?? 1};
`;

export const StyledVideo = styled(Video)`
  overflow: hidden; /* Respect border-radius */
  ${({ $border }) => ($border === 'none' ? null : borderStyles)};
  z-index: ${({ $zIndex }) => $zIndex ?? 1};
  position: relative;
  ${({ $disableCursor }) => $disableCursor && 'pointer-events: none'}

  video {
    position: relative;
  }
`;

export const StyledImage = styled(Image)`
  ${({ $border }) => ($border === 'none' ? null : borderStyles)}
  z-index: ${({ $zIndex }) => $zIndex ?? 1};
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  position: relative;
  ${({ hover, hoverContainerStyle }) =>
    hover &&
    css`
      & > div:first-child {
        ${hoverContainerStyle || ''}
      }

      &:hover {
        & > div:first-child {
          ${hover}
        }
      }
    `}

  ${({ imageContainerStyles }) =>
    imageContainerStyles &&
    css`
      ${imageContainerStyles}
    `}
`;
