import PropTypes from 'prop-types';

import { StyledSection } from './SectionGuard.styles.js';

const SectionGuard = ({
  children,
  section,
  constrained = true,
  small = true,
  inverse = false,
  responsiveBg = false,
  ...props
}) => {
  if (!section) {
    return null;
  }

  // Checks every value inside `section`, aside from the id, and returns true if every value is null
  const allSectionValuesNull = Object.keys(section)
    .filter((key) => key !== 'id')
    .every((key) => {
      return section[key] === null;
    });

  const hasSection = Object.keys(section).length > 0 && !allSectionValuesNull;

  if (hasSection) {
    const hasBackground =
      props.background || Boolean(section?.background?.backgroundType);

    let bgCss = responsiveBg
      ? section?.background?.backgroundCss + section?.background?.responsiveBg
      : section?.background?.backgroundCss;

    return (
      <StyledSection
        background={hasBackground}
        $inverse={inverse}
        css={hasBackground ? bgCss : null}
        constrained={constrained}
        small={small}
        id={section.deepLink}
        hide={section.hide}
        {...props}
      >
        {children ? children(section) : null}
      </StyledSection>
    );
  }
  return null;
};

SectionGuard.propTypes = {
  children: PropTypes.elementType,
  /* Expects the 'Section Item' content type from Contentful */
  section: PropTypes.shape({
    id: PropTypes.string,
  }),
  constrained: PropTypes.bool,
  small: PropTypes.bool,
  inverse: PropTypes.bool,
  responsiveBg: PropTypes.bool,
};

export default SectionGuard;
