import PropTypes from 'prop-types';
import { Image } from '@nintendo-of-america/component-library';

import * as S from './DecoratedHeading.styles.js';

const DecoratedHeading = (props) => {
  const { left, right, children } = props;
  return (
    <S.Row>
      <S.RowItem>
        {left ? <Image assetPath={left} alt="" crop="crop" /> : null}
      </S.RowItem>
      <S.RowItem>{children}</S.RowItem>
      <S.RowItem>
        {right ? <Image assetPath={right} alt="" crop="crop" /> : null}
      </S.RowItem>
    </S.Row>
  );
};

DecoratedHeading.propTypes = {
  // Asset path for the left image
  left: PropTypes.string,
  // Asset path for the right image
  right: PropTypes.string,
  // Heading text
  children: PropTypes.node,
};

export default DecoratedHeading;
