import React from 'react';
import PropTypes from 'prop-types';

import { Spacer } from '@nintendo-of-america/component-library';

import SectionItem from '../SectionItem';

import { TWO_UP_GRID_PROPS } from '../constants.js';

import * as S from './TwoUpGrid.styles';
import useMediaWidth, { MEDIA_TYPE } from '@local/lib/hooks/useMediaWidth';
import { useReducedMotion } from '@shared/util';
import { Grid, GridItem } from '@shared/ui';

const TwoUpGrid = ({
  items,
  left,
  right,
  alternateItems = false,
  stackItems = false,
  imageProps,
  sectionItemProps,
  leftSectionItemProps,
  rightSectionItemProps,
  imageSiblings,
  videoPlayerContainerStyles,
  imageContainerStyles,
  swapOrderOnMobile,
  controls,
  ...props
}) => {
  const isArray = items && items.length > 0;
  const isNonEmptyObject = items && Object.keys(items).length > 0;
  const isTablet = useMediaWidth(MEDIA_TYPE.TABLET);
  const shouldReduceMotion = useReducedMotion();

  const renderAsset = (asset) => {
    if (asset.primary.resourceType === 'video') {
      return (
        <S.VideoPlayerContainer
          $videoPlayerContainerStyles={videoPlayerContainerStyles}
        >
          {!imageProps?.autoplay && (
            <S.StyledVideoPlayer
              assetPath={asset?.primary?.assetPath}
              posterPath={asset?.secondary?.assetPath ?? null}
              $borderColor={imageProps?.borderColor ?? null}
              $border={imageProps?.border ?? null}
              loop={imageProps?.autoplay}
              controls={controls}
              $zIndex={imageProps?.zIndex ?? null}
            />
          )}
          {imageProps?.autoplay && (
            <S.StyledVideo
              assetPath={asset?.primary?.assetPath}
              posterPath={asset?.secondary?.assetPath ?? null}
              $borderColor={imageProps?.borderColor ?? null}
              $border={imageProps?.border ?? null}
              autoplay={true}
              loop={true}
              controls={controls}
              $disableCursor={!shouldReduceMotion}
              $zIndex={imageProps?.zIndex ?? null}
              lazyLoad={imageProps?.lazyLoad ?? true}
              width={600}
              playsInline
            />
          )}
          {imageSiblings}
        </S.VideoPlayerContainer>
      );
    }
    if (asset.primary.resourceType === 'image') {
      return (
        <S.ImageContainer
          $borderColor={imageProps?.borderColor ?? null}
          $border={imageProps?.border ?? null}
          imageContainerStyles={imageContainerStyles}
        >
          <S.StyledImage
            assetPath={asset.primary.assetPath}
            alt={asset.alt || ''}
            $borderColor={imageProps?.borderColor ?? null}
            $border={imageProps?.border ?? null}
            $zIndex={imageProps?.zIndex ?? null}
          />
          {imageSiblings}
        </S.ImageContainer>
      );
    }
    return null;
  };

  const renderGridItem = (direction, item, order) => {
    switch (direction) {
      case 'asset':
        return (
          <GridItem alignSelf="center" order={order}>
            {renderAsset(item)}
          </GridItem>
        );
      case 'caption':
        return (
          <GridItem alignSelf="center" order={order}>
            <SectionItem content={item.caption ?? null} {...sectionItemProps} />
          </GridItem>
        );
      default:
        return (
          <GridItem alignSelf="center" order={order}>
            {direction}
          </GridItem>
        );
    }
  };

  if (isArray) {
    return (
      <Grid {...TWO_UP_GRID_PROPS} {...props}>
        {items.map((item, index) => {
          const isOdd = index % 2 > 0;
          const order = !isTablet && swapOrderOnMobile && isOdd ? -1 : 'unset';

          const additionalProps = isOdd
            ? rightSectionItemProps
            : leftSectionItemProps;
          if (alternateItems) {
            if (isOdd) {
              return (
                <React.Fragment key={`twoupgrid-${index}`}>
                  {right ? renderGridItem(right, item, order) : null}
                  {left ? renderGridItem(left, item, order) : null}
                  {stackItems ? (
                    <GridItem>
                      {renderAsset(item)}
                      <Spacer size={24} />
                      <SectionItem
                        content={item.caption ?? null}
                        {...sectionItemProps}
                        {...additionalProps}
                      />
                    </GridItem>
                  ) : null}
                </React.Fragment>
              );
            }
          }
          return (
            <React.Fragment key={`twoupgrid-${index}`}>
              {left ? renderGridItem(left, item, order) : null}
              {right ? renderGridItem(right, item, order) : null}
              {stackItems ? (
                <GridItem order={order}>
                  {renderAsset(item)}
                  <Spacer size={24} />
                  <SectionItem
                    content={item.caption ?? null}
                    {...sectionItemProps}
                    {...additionalProps}
                  />
                </GridItem>
              ) : null}
            </React.Fragment>
          );
        })}
      </Grid>
    );
  } else if (isNonEmptyObject) {
    const order = !isTablet && swapOrderOnMobile ? -1 : 'unset';
    return (
      <Grid {...TWO_UP_GRID_PROPS} {...props}>
        {renderGridItem(left, items)}
        {renderGridItem(right, items, order)}
      </Grid>
    );
  } else {
    return null;
  }
};

const directionPropType = PropTypes.oneOfType([
  PropTypes.oneOf(['asset', 'caption']),
  PropTypes.node,
]);

const itemPropType = PropTypes.shape({
  alt: PropTypes.string,
  name: PropTypes.string,
  caption: PropTypes.object,
  primary: PropTypes.shape({
    assetPath: PropTypes.string,
    resourceType: PropTypes.string,
  }),
  secondary: PropTypes.shape({
    assetPath: PropTypes.string,
    resourceType: PropTypes.string,
  }),
});

TwoUpGrid.propTypes = {
  /* Two or more pieces of content to render in a 2-column grid. Expects the `imageParser()` object or an array of `imageParser()` objects. */
  items: PropTypes.oneOfType([PropTypes.arrayOf(itemPropType), itemPropType]),
  /* Choose to display an asset or caption in the left column, or explicitly display a node. */
  left: directionPropType,
  /* Choose to display an asset or caption in the right column, or explicitly display a node. */
  right: directionPropType,
  /* Alternate the left and right columns. */
  alternateItems: PropTypes.bool,
  /* Display all of one item in a single column. */
  stackItems: PropTypes.bool,
  /* Pass image props to `Image` component. Customize border. */
  imageProps: PropTypes.shape({
    borderColor: PropTypes.string,
    border: PropTypes.string,
    autoplay: PropTypes.bool,
    lazyLoad: PropTypes.bool,
  }),
  /* Props passed to included SectionItems */
  sectionItemProps: PropTypes.shape(SectionItem.propTypes),
  /* Props passed to only the Left section item*/
  leftSectionItemProps: PropTypes.shape(SectionItem.propTypes),
  /* Props passed to only the Right section item*/
  rightSectionItemProps: PropTypes.shape(SectionItem.propTypes),
  /* An array of JSX to be rendered alongside images. Intended for absolutely positioned elements to sit on top of / behind the grid image*/
  imageSiblings: PropTypes.array,
  /* Allow styles (like a padded border) around the VideoPlayer*/
  videoPlayerContainerStyles: PropTypes.string,
  /* Allow the image container to take custom styles */
  imageContainerStyles: PropTypes.string,
  /* Set the Left (desktop) element to the Bottom (mobile) -- instead of the default Top(mobile) */
  swapOrderOnMobile: PropTypes.bool,
};

export default TwoUpGrid;
