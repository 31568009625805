import styled from 'styled-components';

const PMPWrap = styled.div`
  ${({ theme }) => theme.mediaQuery.tablet} {
    > * {
      &:first-child {
        padding-top: ${({ theme }) => theme.spacing[80]};
      }
    }
  }
`;

export default PMPWrap;
