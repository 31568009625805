import styled from 'styled-components';

import { Image, VideoPlayer } from '@nintendo-of-america/component-library';

import { borderStyles } from '../border.styles';

export const StyledImage = styled(Image)`
  ${({ border }) => (border === 'none' ? null : borderStyles)};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
`;

export const StyledVideoPlayer = styled(VideoPlayer)`
  ${({ border }) => (border === 'none' ? null : borderStyles)};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
`;
