import PropTypes from 'prop-types';

import { Link } from '@nintendo-of-america/component-library';
import { Grid, GridItem } from '@shared/ui';

import SectionItem from '../SectionItem';

import { THREE_UP_GRID_PROPS } from '../constants.js';

import * as S from './ThreeUpGrid.styles';

const ThreeUpGrid = ({ items, imageProps, sectionItemProps, ...props }) => {
  const hasItems = items && items.length > 0;
  if (hasItems) {
    return (
      <Grid {...THREE_UP_GRID_PROPS} {...props}>
        {items.map((item, index) => (
          <GridItem key={`${item.primary.assetPath}-${index}`}>
            {item.primary?.resourceType === 'image' && (
              <S.StyledImage
                assetPath={item.primary.assetPath}
                alt={item.alt || ''}
                {...imageProps}
              />
            )}
            {item.primary?.resourceType === 'video' && (
              <S.StyledVideoPlayer
                assetPath={item.primary.assetPath}
                {...imageProps}
              />
            )}
            <SectionItem
              heading={item.heading}
              content={item.caption ?? null}
              {...sectionItemProps}
            />
            {item.ctaLink && (
              <Link href={item.ctaLink.url}>{item.ctaLink.label}</Link>
            )}
          </GridItem>
        ))}
      </Grid>
    );
  }
  return null;
};

const itemPropType = PropTypes.shape({
  alt: PropTypes.string,
  name: PropTypes.string,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  primary: PropTypes.shape({
    assetPath: PropTypes.string,
    resourceType: PropTypes.string,
  }),
  secondary: PropTypes.shape({
    assetPath: PropTypes.string,
    resourceType: PropTypes.string,
  }),
  ctaLink: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),
});

ThreeUpGrid.propTypes = {
  items: PropTypes.arrayOf(itemPropType),
  imageProps: PropTypes.shape({
    borderColor: PropTypes.string,
    border: PropTypes.string,
  }),
  sectionItemProps: PropTypes.shape(SectionItem.propTypes),
};

export default ThreeUpGrid;
