import styled from 'styled-components';

export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[16]};
  text-align: center;
`;

export const RowItem = styled.div`
  flex: 0 1 auto;
`;
